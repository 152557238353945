// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$grid-columns:          16 !default;

$main-sm-columns:       16;
$sidebar-sm-columns:    4;

// Colors
$ahl-white:				#f4f4f4;
$ahl-beige:				#cfc6ab;
$ahl-red:				#cc0000; // #d2232a;
$ahl-umbra:				#6c5942; // #978471;
$ahl-green:				#69b766;
$ahl-blue:				#009acf;
$ahl-blue-dark:			#0067a9;
$ahl-orange:			#edb029; // #e78405; 

$ahl-urlicic:			#e5dbbd;

$ahl-gray-light:		#afafaf;
$ahl-gray:				#535353;
$ahl-gray-dark:			#373737;
$ahl-black:				#000000;

$brand-primary:         $ahl-blue;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Freight-Sans-Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       "Adelle", Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-serif !default;

$font-size-base:          16px !default;