/* aerztehaus.wien */

// General Styles
html { height: 100%; background: $ahl-white; }
body { text-rendering: optimizeLegibility; position: relative; min-height: 100%; background: transparent; }

// Responsive Behaviour & Tidbits
// p { -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -o-hyphens: auto; hyphens: auto; }
.thumb { margin-top: 16px; margin-bottom: 16px;
 a { display: block; }
 img { max-width: 100%; height: auto; }
}
.segment ul { padding-left: 19px; }
img { max-width: 100%; height: auto; }
.centered { text-align: center; }
@media (max-width: $screen-xs-max) {
  .centered-xs {
    text-align: center !important;  
  }
}
.grayline { padding-bottom: 42px; border-bottom: 2px solid $ahl-gray-dark; }
.verticallycentered { position: absolute; width: 100%; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%); }
.left, .left .justify { text-align: left; }
.right, .right .justify { text-align: right; }
.hidden { display: none; }
// .hyphenated { -webkit-hyphens: auto; -moz-hyphens: auto; -ms-hyphens: auto; -o-hyphens: auto; hyphens: auto; }
// .not-hyphenated { -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none; -o-hyphens: none; hyphens: none; }
.justified { text-align: justify; }
.thumb { display: inline-block; max-width: 100%; position: relative; }
.noselect { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

h2, .h2 { color: $ahl-green; font-family: $font-family-sans-serif; text-transform: uppercase; font-size: 30px; font-weight: 600; margin-bottom: 42px; }
@media (min-width: $screen-sm-min) {
  h2, .h2 { margin-left: -15px; }
}
h3, .h3 { color: $ahl-blue-dark; font-size: 24px; font-weight: 600; letter-spacing: 0.02em; }
h4, .h4 { font-weight: 600; text-transform: uppercase; font-family: $font-family-sans-serif; font-size: 20px; line-height: 24px; margin: 0 0 24px 0;
  a, a:link, a:visited { color: $ahl-black; }
  a:focus, a:hover { text-decoration: underline; }
  a.url { text-transform: lowercase; color: $ahl-gray; }
}

p, dt, dd { line-height: 1.5em; }
p small { font-size: 14px; }
.dl-horizontal dt { font-weight: normal; text-align: left; }
dt.wide { overflow: visible; }
dd.wide { padding-top: 1.5em; }

@media (max-width: $screen-xs-max) {
  dd { margin-bottom: 1.5em; }
  dd.wide { padding-top: 0; }
}

// Visitenkarten
.vcard { position: relative; z-index: 1; margin-bottom: 2em; padding: 0; // min-height: 242px;
  img { z-index: 2; top: 0; left: 0; margin-left: 15px; }
  .doctor { position: absolute; z-index: 6; right: 0; top: 30px; }
  h1 { font-size: 30px; line-height: 38px; font-weight: 400; letter-spacing: 0.0142em; text-align: right; font-family: $font-family-sans-serif; margin-top: 0; margin-bottom: 4px;
	b { text-transform: uppercase; font-weight: 400; }
	a:focus, a:hover { text-decoration: none; outline: none; }
  }
  h4 { text-align: right; font-size: 18px; letter-spacing: 0.0142em; margin-bottom: 13px; font-weight: 400;
	a:focus, a:hover { text-decoration: none; outline: none; }
  }
  .caption { position: absolute; z-index: 3; bottom: 17px; right: 0; }
  h2 { margin: 0;  color: #fff; text-transform: none; letter-spacing: 0.042em; font-size: 18px; font-weight: 400; text-align: right; }
  .logo { width: 280px; display: block; margin-top: 10px;
    img { margin-left: 0; }
  }
}
/*
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .vcard { min-height: 260px; }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .vcard { min-height: 280px; }
}
@media (min-width: $screen-lg-min) {
  .vcard { min-height: 300px; }
}
*/
@media (max-width: $screen-xs-max) {
  .vcard {
    img { margin-left: 0; }
    .doctor { right: 15px; }
    .caption { right: 15px; }
    }
}

h4 a.lightbox, h4 a:link.lightbox, h4 a:visited.lightbox { font-weight: 400; color: $ahl-gray; }
h4 a:hover.lightbox { color: $ahl-black; }
.lightbox-content { display: none; }

// Mods
.urlicic {
  h1 {
    a, a:link, a:visited { color: $ahl-beige; color: $ahl-umbra; }
    a:hover, a:focus { color: darken( $ahl-umbra, 8% ); }
  }
  .arrow { display: none; }
}
.nivo-lightbox-content .urlicic {
  h1 {
    a, a:link, a:visited { color: $ahl-umbra; }
    a:hover, a:focus { color: darken( $ahl-umbra, 8% ); }
  }
  a, a:link, a:visited { color: $ahl-umbra; font-weight: 600; }
  a:hover, a:focus { color: darken( $ahl-umbra, 8% ); }
}

.krancan {
  h1 {
    a, a:link, a:visited { color: $ahl-orange; }
    a:hover, a:focus { color: darken( $ahl-orange, 8% ); }
  }
  .logo { margin-top: 0 !important; }
}
.nivo-lightbox-content .krancan {
  h1 {
    a, a:link, a:visited { color: $ahl-orange; }
    a:hover, a:focus { color: darken( $ahl-orange, 8% ); }
  }
  a, a:link, a:visited { color: $ahl-orange; font-weight: 600; }
  a:hover, a:focus { color: darken( $ahl-orange, 8% ); }
}

.pircher {
  h1 {
    a, a:link, a:visited { color: $ahl-blue; }
    a:hover, a:focus { color: darken( $ahl-blue, 8% ); }
  }
  h2 { color: $ahl-blue; }
}
.nivo-lightbox-content .pircher {
  h1 {
    a, a:link, a:visited { color: $ahl-blue; }
    a:hover, a:focus { color: darken( $ahl-blue, 8% ); }
  }
  a, a:link, a:visited { color: $ahl-blue; font-weight: 600; }
  a:hover, a:focus { color: darken( $ahl-blue, 8% ); }
}

.helmer {
  h1 {
    a, a:link, a:visited { color: $ahl-red; }
    a:hover, a:focus { color: darken( $ahl-red, 8% ); }
  }
  h2 { color: $ahl-red; }
}
.nivo-lightbox-content .helmer {
  h1 {
    a, a:link, a:visited { color: $ahl-red; }
    a:hover, a:focus { color: darken( $ahl-red, 8% ); }
  }
  a, a:link, a:visited { color: $ahl-red; font-weight: 600; }
  a:hover, a:focus { color: darken( $ahl-red, 8% ); }
}
  
// Lightbox
.nivo-lightbox-content {
  h1 { margin-top: 0; }
  h3, .h3 { color: $ahl-gray-dark; font-size: 20px; font-weight: 600; letter-spacing: 0.02em; }
  h4, .h4 { font-weight: 600; text-transform: none; font-family: $font-family-serif; font-size: 16px; line-height: 22px; margin: 0 0 22px 0; }
  h4+ul { margin-top: -16px; }
  h5 { line-height: 1.5em; font-style: italic; }
  ul { padding-left: 17px; }
  li { font-size: 15px; line-height: 21px; }
  .kontaktblock { padding-top: 11px; }
  .dl-horizontal dt { width: 2em; }
  .dl-horizontal dd { margin-left: 2em; }
}

// Zufahrt
#zufahrt { border-top: 4px $ahl-gray-light dotted; margin-top: 2em; padding-top: 2em; }

// Partner
#partner { border-top: 4px $ahl-gray-light dotted; margin-top: 2em; padding-top: 2em; }

#labors-at { margin-top: 14px; display: block; 
  &:focus { outline: none; }
}

// Anschrift
#anschrift { background: #fff; border-top: 5px $ahl-gray-light solid; margin-top: 2em; padding-top: 3em; padding-bottom: 3em; margin-bottom: 2em;
  .map-holder { padding: 0; }
  .nopadding { padding: 0; }
  #map { width: 100%; height: 342px; }
}

// Header
.brand { margin: 28px 0 13px 0; display: block; }

// Footer
.imprint { padding-top: 1em; }
.btn-impressum { cursor: pointer; }

// Impressum
#impressum { color: $ahl-gray-light; border-top: 5px solid $ahl-red; padding-top: 2.42em;
  background: $ahl-gray-dark; background-size:345px 345px;
  h2, .h2 { color: $ahl-gray-light; }
  h1:after, h2:after, h3.gform_title:after { content: ""; display: none; }
  h2.who { color: darken($ahl-white, 7%); }
  .h3 { color: darken($ahl-white, 7%); }
  .h4 { font-size: 21px; line-height: 28px; }
  a { color: $ahl-gray-light;
	a:link, a:visited { color: $ahl-gray-light; }
	a:hover; a:focus { color: $ahl-gray-light; }
  }
//  p { -webkit-hyphens: none; -moz-hyphens: none; -ms-hyphens: none; -o-hyphens: none; hyphens: none; } 
  small, small b { color: lighten( $ahl-gray, 9% ); }
  hr { width: 7em; border-top: 3px solid #9da099; margin-top: 42px; margin-bottom: 42px; }
  hr.dotted { border-top: 3px dotted #9da099; }
}